import { PropsWithChildren, ReactNode, useState } from 'react';
import { FocusScope } from 'react-aria';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components-shad/ui/dialog';
import { Button } from '@/components-shad/ui/button';
import { cn } from '@/shared/helpers';
import { Result } from '@/shared/helpers/Result';

// This doesn't handle transformed values types. Adding this in seems to cause a bunch of typing havoc.
// We may not need transformed values, so leaving it out for now.
type Props<TFieldValues extends FieldValues = FieldValues> = PropsWithChildren<{
  form: Pick<UseFormReturn<TFieldValues>, 'handleSubmit'>;
  onSubmit: (data: TFieldValues) => void | Promise<Result> | Promise<void>;
  title: ReactNode;
  description?: ReactNode;
  cancelLabel?: ReactNode;
  submitLabel?: ReactNode;
  isSubmitDisabled?: boolean;
  className?: string;
}>;

export function FormDialog<TFieldValues extends FieldValues = FieldValues>({
  form: { handleSubmit },
  onSubmit,
  title,
  description,
  cancelLabel = 'Cancel',
  submitLabel = 'Save',
  isSubmitDisabled,
  className,
  children,
}: Props<TFieldValues>) {
  const [isPending, setIsPending] = useState(false);

  return (
    <DialogContent className={cn('sm:max-w-[480px]', className)}>
      {({ close }) => (
        <form
          className="flex h-full flex-col gap-8"
          onSubmit={handleSubmit(async (data) => {
            setIsPending(true);
            try {
              const result = await onSubmit(data);
              if (!result || result.isSuccess) close();
            } finally {
              setIsPending(false);
            }
          })}
        >
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            {description && <DialogDescription>{description}</DialogDescription>}
          </DialogHeader>

          <div className="flex flex-col gap-6">
            <FocusScope autoFocus>{children}</FocusScope>
          </div>

          <DialogFooter>
            <Button type="button" variant="secondary" onPress={close}>
              {cancelLabel}
            </Button>
            <Button type="submit" isPending={isPending} isDisabled={isSubmitDisabled}>
              {submitLabel}
            </Button>
          </DialogFooter>
        </form>
      )}
    </DialogContent>
  );
}
