import { AccessContext } from '@invenco/common-interface/auth';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useFeatureFlags } from '@/shared/providers/FeatureFlagProvider';
import { NavigationSidebar } from '@/pages/home/navigation-shad/NavigationSidebar';
import { PageContainer, RouteContainer } from '@/styles/layout';
import { SidebarInset, SidebarProvider } from '@/components-shad/ui/sidebar';
import { Navigation } from './navigation/Navigation';
import { SellerRoutes } from '../seller/routes';
import { PlatformRoutes } from '../platform/routes';
import { NoContextRoutes } from '../no-context/routes';
import { WarehouseRoutes } from '../warehouse/routes';
import { useAuthToken } from '../../shared/hooks/useAuthToken';

function HomeImpl() {
  const { accessContext } = useAuthToken();
  const { getFeatureFlag } = useFeatureFlags();

  const getRoutes = () => {
    switch (accessContext) {
      case AccessContext.SELLER:
        return <SellerRoutes />;
      case AccessContext.PLATFORM:
        return <PlatformRoutes />;
      case AccessContext.WAREHOUSE:
        return <WarehouseRoutes />;
      default:
        return <NoContextRoutes />;
    }
  };

  // TODO LYNK-3372 remove surrounding if
  if (getFeatureFlag('new-ui') && accessContext === AccessContext.PLATFORM) {
    return (
      <SidebarProvider>
        <NavigationSidebar />
        <SidebarInset>{getRoutes()}</SidebarInset>
      </SidebarProvider>
    );
  }

  // TODO LYNK-3372 delete this return statement
  return (
    <PageContainer>
      <Navigation />
      <RouteContainer>{getRoutes()}</RouteContainer>
    </PageContainer>
  );
}

export const Home = withAuthenticationRequired(HomeImpl);
