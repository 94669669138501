import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useFeatureFlags } from '@/shared/providers/FeatureFlagProvider';
import { AccessContext } from '@invenco/common-interface/auth';
import { useAuthToken } from '../hooks/useAuthToken';

export type Theme = 'dark' | 'light' | 'system';

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const THEME_STORAGE_KEY = 'lynk/theme';

const ThemeProviderContext = createContext<ThemeProviderState>({
  theme: 'system',
  setTheme: () => {},
});

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(THEME_STORAGE_KEY) as Theme) || 'light',
  );
  const { getFeatureFlag } = useFeatureFlags();

  const { accessContext } = useAuthToken();

  /*
   * This useEffect conditionally adds css styles needed by the new UI.
   * We are aiming to deploy the new UI to production incrementally by Access Context.
   * To use the new UI users need to enable the feature and be logged in with a supported Context
   * TODO LYNK-3372: Delete this useEffect
   */
  useEffect(() => {
    const root = window.document.documentElement;
    if (getFeatureFlag('new-ui') && accessContext === AccessContext.PLATFORM) {
      root.classList.add('mcshadwind');
    }
    return () => root.classList.remove('mcshadwind');
  }, [accessContext, getFeatureFlag]);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const value = useMemo(
    () => ({
      theme,
      setTheme: (newTheme: Theme) => {
        localStorage.setItem(THEME_STORAGE_KEY, newTheme);
        setTheme(newTheme);
      },
    }),
    [theme],
  );

  return <ThemeProviderContext.Provider value={value}>{children}</ThemeProviderContext.Provider>;
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
