import React from 'react';
import { ChevronDownIcon, LoaderCircleIcon } from 'lucide-react';
import {
  Collection,
  ComboBox as AriaComboBox,
  ComboBoxProps as AriaComboBoxProps,
  composeRenderProps,
  Input as AriaInput,
  InputProps as AriaInputProps,
  ListBox as AriaListBox,
  ListBoxProps as AriaListBoxProps,
  PopoverProps as AriaPopoverProps,
  ValidationResult as AriaValidationResult,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { Button } from './button';
import { FieldDescription, FieldError, FieldGroup, Label } from './field';
import { ListBoxHeader, ListBoxItem, ListBoxSection } from './list-box';
import { Popover } from './popover';

const BaseCombobox = AriaComboBox;

const ComboboxItem = ListBoxItem;

const ComboboxHeader = ListBoxHeader;

const ComboboxSection = ListBoxSection;

const ComboboxCollection = Collection;

const ComboboxInput = React.forwardRef<React.ElementRef<typeof AriaInput>, AriaInputProps>(
  ({ className, ...props }, ref) => (
    <AriaInput
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'flex h-10 w-full bg-background px-3 py-2 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
          /* Disabled */
          'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
          composedClassName,
        ),
      )}
      {...props}
    />
  ),
);

const ComboboxPopover = React.forwardRef<React.ElementRef<typeof Popover>, AriaPopoverProps>(
  ({ className, ...props }, ref) => (
    <Popover
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn('w-[calc(var(--trigger-width)+4px)]', composedClassName),
      )}
      {...props}
    />
  ),
);

function ComboboxListBox<T extends object>({ className, ...props }: AriaListBoxProps<T>) {
  return (
    <AriaListBox
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'max-h-[inherit] overflow-auto p-1 outline-none [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

interface ComboboxProps<T extends object>
  extends Omit<AriaComboBoxProps<T>, 'children'>,
    Pick<AriaListBoxProps<T>, 'children'> {
  label?: string;
  description?: string | null;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  isLoading?: boolean;
}

// TODO: remove this once upgraded to React 19
type ComboboxRefT = React.Ref<React.ElementRef<typeof ComboboxInput>>;
const Combobox = React.forwardRef(
  <T extends object>(
    {
      label,
      description,
      errorMessage,
      isLoading,
      className,
      children,
      ...props
    }: ComboboxProps<T>,
    ref: ComboboxRefT,
  ) => (
    <BaseCombobox
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      <Label>{label}</Label>
      <FieldGroup className="p-0">
        <ComboboxInput ref={ref} />
        <Button
          variant="ghost"
          className="mr-1 size-6 p-1"
          icon={
            isLoading ? (
              // TODO: LYNK-3438 replace with accessible loader
              <LoaderCircleIcon aria-hidden="true" className="size-4 animate-spin opacity-50" />
            ) : (
              <ChevronDownIcon aria-hidden="true" className="size-4 opacity-50" />
            )
          }
        />
      </FieldGroup>
      {description && <FieldDescription>{description}</FieldDescription>}
      <FieldError>{errorMessage}</FieldError>
      <ComboboxPopover>
        <ComboboxListBox>{children}</ComboboxListBox>
      </ComboboxPopover>
    </BaseCombobox>
  ),
) as <T extends object>(props: ComboboxProps<T> & { ref?: ComboboxRefT }) => React.ReactElement;

export {
  ComboboxSection,
  BaseCombobox,
  ComboboxListBox,
  ComboboxInput,
  ComboboxCollection,
  ComboboxItem,
  ComboboxHeader,
  ComboboxPopover,
  Combobox,
};
export type { ComboboxProps };
