import {
  Controller,
  ControllerProps,
  DefaultValues,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import { TextField, TextFieldProps } from '@/components-shad/ui/text-field';
import { DatePicker, DatePickerProps } from '@/components-shad/ui/date-picker';
import { CalendarDate, parseDate } from '@internationalized/date';
import { Select, SelectProps } from '@/components-shad/ui/select';
import * as z from 'zod';
import { getISODate } from '@/shared/helpers';
import { Combobox, ComboboxProps } from '@/components-shad/ui/combobox';

/**
 * Produces a default values object for a form, given a Zod schema and an optional DTO object.
 *
 * The schema can be a ZodObject or a ZodEffects (generated via refine(), etc.) which wraps a ZodObject.
 * It will iterate the shape of the schema object and fetch any key it finds in the DTO. If a date string is expected
 * it will ensure the incoming ISO datetime is truncated.
 *
 * It does not currently work with nested objects / fields.
 */
export const getFormDefaults = <
  ShapeT extends z.ZodRawShape,
  SchemaT extends z.ZodObject<ShapeT> | z.ZodEffects<z.ZodObject<ShapeT>>,
  DataT extends FieldValues = z.infer<SchemaT>,
>(
  schema: SchemaT,
  dto?: Record<string, any>,
): DefaultValues<DataT> => {
  if (!dto) {
    return {} as DefaultValues<DataT>;
  }
  const shape = schema instanceof z.ZodObject ? schema.shape : schema.innerType().shape;
  return Object.fromEntries(
    Object.entries(shape).map(([key, schemaField]) => {
      let type = schemaField;
      if (schemaField instanceof z.ZodOptional || schemaField instanceof z.ZodNullable) {
        type = schemaField.unwrap();
      }
      let value = dto[key];
      if (type instanceof z.ZodString && type.isDate) {
        // ensure ISO datetime value is truncated into just ISO date for the sake of parseDate
        value = value ? getISODate(value) : undefined;
      }
      return [key, value];
    }),
  ) as DefaultValues<DataT>;
};

/*
  The components below connect React Hook Form to the input components from the UI library by wrapping
  them in the Controller component.

  They do the following:
    * Map field props from the controller to input
    * Map field error state from the controller to input
    * Perform type conversions between form state and input state as needed
    * Avoid uncontrolled input warnings by providing a default value to the input
 */

type FormFieldProps<
  Props,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Props & Pick<ControllerProps<TFieldValues, TName>, 'control' | 'name'>;

export function FormTextField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  isDisabled,
  defaultValue,
  ...props
}: FormFieldProps<TextFieldProps, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      render={({ field, fieldState }) => (
        <TextField
          {...props}
          ref={field.ref}
          name={field.name}
          value={field.value ?? ''} // avoid uncontrolled input warning
          onChange={field.onChange}
          isDisabled={field.disabled}
          onBlur={field.onBlur}
          isInvalid={fieldState.invalid || props.isInvalid}
          errorMessage={fieldState.error?.message || props.errorMessage}
        />
      )}
    />
  );
}

export function FormDatePicker<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  isDisabled,
  ...props
}: FormFieldProps<DatePickerProps<CalendarDate>, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      render={({ field, fieldState }) => (
        <DatePicker
          granularity="day"
          hideTimeZone
          {...props}
          ref={field.ref}
          name={field.name}
          value={field.value ? parseDate(field.value as string) : null} // avoid uncontrolled input warning
          onChange={(val) => field.onChange(val ? val.toString() : '')}
          onBlur={field.onBlur}
          isDisabled={field.disabled}
          isInvalid={fieldState.invalid || props.isInvalid}
          errorMessage={fieldState.error?.message || props.errorMessage}
        />
      )}
    />
  );
}

export function FormSelect<
  T extends object,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  isDisabled,
  children,
  ...props
}: FormFieldProps<SelectProps<T>, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      render={({ field, fieldState }) => (
        <Select
          {...props}
          ref={field.ref}
          name={field.name}
          selectedKey={field.value ?? null} // avoid uncontrolled input warning
          onSelectionChange={field.onChange}
          onBlur={field.onBlur}
          isDisabled={field.disabled}
          isInvalid={fieldState.invalid || props.isInvalid}
          errorMessage={fieldState.error?.message || props.errorMessage}
        >
          {children}
        </Select>
      )}
    />
  );
}

export function FormCombobox<
  T extends object,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  isDisabled,
  children,
  ...props
}: FormFieldProps<ComboboxProps<T>, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      render={({ field, fieldState }) => (
        <Combobox
          {...props}
          ref={field.ref}
          name={field.name}
          selectedKey={field.value ?? null} // avoid uncontrolled input warning
          onSelectionChange={field.onChange}
          onBlur={field.onBlur}
          isDisabled={field.disabled}
          isInvalid={fieldState.invalid || props.isInvalid}
          errorMessage={fieldState.error?.message || props.errorMessage}
          menuTrigger="focus"
        >
          {children}
        </Combobox>
      )}
    />
  );
}
