import { Button } from '@/components-shad/ui/button';
import { RotateCwIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/shared/helpers';

type Props = {
  onPress: () => Promise<void>;
  className?: string;
};

const MINIMUM_DURATION = 300;

export function ReloadButton({ onPress, className }: Props) {
  const [isPending, setIsPending] = useState(false);
  const unmountedRef = useRef(false);

  useEffect(() => {
    unmountedRef.current = false;
    setIsPending(false);
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return (
    <Button
      variant="outline"
      aria-label="Reload table"
      className={cn('group', className)}
      isPending={isPending}
      isDisabled={isPending}
      showPendingSpinner={false}
      onPress={async () => {
        setIsPending(true);
        const now = Date.now();
        await onPress();
        const duration = Date.now() - now;
        setTimeout(
          () => {
            if (!unmountedRef.current) {
              setIsPending(false);
            }
          },
          Math.max(0, MINIMUM_DURATION - duration),
        );
      }}
      icon={
        <div className="group-data-[pending]:animate-spin group-data-[pending]:delay-300">
          <RotateCwIcon className="transition-transform group-data-[pending]:scale-90" />
        </div>
      }
    />
  );
}
