import { Fragment, ReactNode, useId } from 'react';
import { cn } from '@/shared/helpers';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { NA_TEXT } from '@/constants';

type Props = {
  items: Array<{ label: string; value: ReactNode }>;
  title?: string;
  loading?: boolean;
  className?: string;
};

export function DetailList({ items, title, loading, className }: Props) {
  const titleId = useId();
  return (
    <div className="space-y-2">
      {title && (
        <span id={titleId} className="text-sm font-semibold">
          {title}
        </span>
      )}
      <dl
        className={cn('grid max-w-full grid-cols-[30%_1fr] gap-2 text-sm', className)}
        aria-labelledby={title ? titleId : undefined}
      >
        {items.map(({ label, value }) => (
          <Fragment key={label}>
            <dt className="text-muted-foreground">{label}</dt>
            <dd className="break-all text-right">
              {loading ? (
                <Skeleton className="h-4 w-full" />
              ) : (
                (value ?? <span className="text-muted-foreground">{NA_TEXT}</span>)
              )}
            </dd>
          </Fragment>
        ))}
      </dl>
    </div>
  );
}
